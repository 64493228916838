import api from '@/utils/ajax'

export default {
  namespaced: true,

  state: {},

  getters: {},

  actions: {
    /**
     * （没传入id）增加或（传入id）修改
     * @param {*} ctx
     * @param {Number} id id
     * @param {String} name 名称
     * @param {String} resourceNameHorEn 横屏英文资源名称
     * @param {String} resourceNameHorZhcn 横屏简体中文资源名称
     * @param {String} resourceNameHorZhtw 横屏繁体中文资源名称
     * @param {String} resourceNameVerEn  英文资源名称
     * @param {String} resourceNameVerZhcn  简体中文资源名称
     * @param {String} resourceNameVerZhtw  繁体中文资源名称
     * @param {String} resourceUrlHorEn  横屏英文资源url
     * @param {String} resourceUrlHorZhcn  横屏简体中文资源url
     * @param {String} resourceUrlHorZhtw  横屏繁体中文资源url
     * @param {String} resourceUrlVerEn  英文资源url
     * @param {String} resourceUrlVerZhcn  简体中文资源url
     * @param {String} resourceUrlVerZhtw  繁体中文资源url
     */
    aiPuzzleManageSave(ctx, {
      id,
      name,
      resourceNameHorEn,
      resourceNameHorZhcn,
      resourceNameHorZhtw,
      resourceNameVerEn,
      resourceNameVerZhcn,
      resourceNameVerZhtw,
      resourceUrlHorEn,
      resourceUrlHorZhcn,
      resourceUrlHorZhtw,
      resourceUrlVerEn,
      resourceUrlVerZhcn,
      resourceUrlVerZhtw,
    } = {}) {
      return api.post('/api/admin/instance/ganAiPuzzle/save', {
        data: {
          id,
          name,
          resourceNameHorEn,
          resourceNameHorZhcn,
          resourceNameHorZhtw,
          resourceNameVerEn,
          resourceNameVerZhcn,
          resourceNameVerZhtw,
          resourceUrlHorEn,
          resourceUrlHorZhcn,
          resourceUrlHorZhtw,
          resourceUrlVerEn,
          resourceUrlVerZhcn,
          resourceUrlVerZhtw,
        },
        // toastError: false// 把默认的后端返回默认报错提示去掉，前端自己设置报错提示信息
      })
    },
    /**
     * 图片数据上传的数据列表
     * @param {*} ctx
     * @param {Number} page
     * @param {Number} size
     */
    aiPuzzleManageList(ctx, { page, size } = {}) {
      return api.get('/api/admin/instance/ganAiPuzzle/list', {
        params: {
          page,
          size
        }
      })
    },

  }
}
