// @file 数据统计
import api from '@/utils/ajax'

export default {
  namespaced: true,

  state: {
    // 数据面板汇总数据
    visitorSummary: {
      registered: 0,
      visitors: 0
    },
    // 数据面板列表总计数据
    visitorSummaryDate: {
      registered: 0,
      visitors: 0
    },
    // 事件分析汇总数据
    actionSummary: {
      normalUser: 0,
      puzzleUser: 0,
      creationUser: 0,
      puzzleAndCreationUser: 0
    },
    // 事件分析列表总计数据
    actionSummaryDate: {
      normalUser: 0,
      puzzleUser: 0,
      creationUser: 0,
      puzzleAndCreationUser: 0
    }
  },

  mutations: {
    setVisitorSummary(state, visitorSummary) {
      state.visitorSummary = visitorSummary
    },
    setVisitorSummaryDate(state, visitorSummaryDate) {
      state.visitorSummaryDate = visitorSummaryDate
    },
    setActionSummary(state, actionSummary) {
      state.actionSummary = actionSummary
    },
    setActionSummaryDate(state, actionSummaryDate) {
      state.actionSummaryDate = actionSummaryDate
    },
  },

  actions: {
    /**
     * 注册访问汇总(不传日期则返回所有)
     * @param {*} ctx
     * @param {String} language 语言
     * @param {String} startDay 开始日期时间戳
     * @param {String} endDay 结束日期时间戳
     * @param {String} type 用户类型 wx、h5
     */
    async visitorRegisterSummary(ctx, { language, startDay, endDay, type } = {}) {
      const data = await api.get('/api/admin/instance/statistic/visitor-registered-summary', {
        params: {
          language,
          startDay,
          endDay,
          type
        }
      })
      if (!startDay) {
        ctx.commit('setVisitorSummary', data)
      } else {
        ctx.commit('setVisitorSummaryDate', data)
      }
      return data
    },

    /**
     * 注册访问量列表
     * @param {*} ctx
     * @param {String} language 语言
     * @param {String} startDay 开始日期时间戳
     * @param {String} endDay 结束日期时间戳
     * @param {String} page 页码
     * @param {String} size 每页条数
     * @param {String} type 用户类型 wx、h5
     */
    async visitorRegisterList(ctx, { language, startDay, endDay, page, size, type } = {}) {
      return await api.get('/api/admin/instance/statistic/visitor-registered-list', {
        params: {
          language,
          startDay,
          endDay,
          page,
          size,
          type
        }
      })
    },

    /**
     * 事件分析汇总
     * @param {*} ctx
     * @param {String} language 语言
     * @param {String} type 用户类型 wx、h5
     */
    async getActionSummary(ctx, { language, type } = {}) {
      const data = await api.get('/api/admin/instance/statistic/action-summary', {
        params: {
          language,
          type
        }
      })
      ctx.commit('setActionSummary', data)
      return data
    },

    /**
     * 事件分析列表
     * @param {*} ctx
     * @param {String} language 语言
     * @param {String} startDay 开始日期时间戳
     * @param {String} endDay 结束日期时间戳
     * @param {String} page 页码
     * @param {String} size 每页条数
     * @param {String} type 用户类型 wx、h5
     */
    async getActionList(ctx, { language, startDay, endDay, page, size, type } = {}) {
      return await api.get('/api/admin/instance/statistic/action-list', {
        params: {
          language,
          startDay,
          endDay,
          page,
          size,
          type
        }
      })
    },
  }
}
