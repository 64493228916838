import api from '@/utils/ajax'

export default {
  namespaced: true,

  state: {},

  getters: {},

  actions: {
    /**
     * 获取玩家案例列表
     * @param {*} ctx
     * @param {Number} startDay 开始日期时间戳,13位
     * @param {Number}  endDay 结束日期时间戳,13位
     * @param {Number} onlyCreation 是否只看创作型用户作品 1为是 0为否
     * @param {Number} page 页码
     * @param {Number} size 每页条数
     */
    async playerCaseManageList(ctx, { ...params } = {}) {
      return await api.get('/api/admin/instance/ganCreation/list', {
        params
      })
    },
    /**
     * 获取玩家案例详情
     * @param {*} ctx
     * @param {String} id
     */
    async playerCaseManageDetail(ctx, { id } = {}) {
      return await api.get('/api/admin/instance/ganCreation/get', {
        params: {
          id
        }
      })
    },
    /**
     * 获取玩家案例详情作品下载
     * @param {*} ctx
     * @param {String} url 地址
     * @param {String} id id
     */
    playerCaseManageDownload(ctx, { url, id } = {}) {
      return api.export(url, {
        id
      })
    },
  },

  mutations: {}
}
