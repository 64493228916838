// @file 颜色管理
import api from '@/utils/ajax'

export default {
  namespaced: true,

  state: {},

  getters: {},

  actions: {
    /**
     * 颜色库管理列表
     * @param {*} ctx
     * @param {Number} id 标记id
     */
    colourManageList(ctx, { ...params } = {}) {
      return api.get('/api/admin/instance/ganColorSheet/list', {
        params
      })
    },
    /** 颜色库排序
     * @param {*} ctx
     * @param {Number} id id
     * @param {Number} type 类型：1为上移 2为下移
     */
    colourSort(ctx, { id, type } = {}) {
      return api.post('/api/admin/instance/ganColorSheet/sort', {
        data: {
          id,
          type
        }
      })
    },
    /**
     * 删除指定列
     * @param {*} ctx
     * @param {Number} id 标识id
     */
    colourManageRemove(ctx, { id } = {}) {
      return api.post('/api/admin/instance/ganColorSheet/delete', {
        data: {
          id,
        }
      })
    },
    /**
     * （没传入id）增加或（传入id）修改颜色库
     * @param {*} ctx
     * @param {Number} id 颜色库id
     * @param {String} nameZhcn 中文名称颜色库名称
     * @param {String} nameZhtw 繁体名称颜色库名称
     * @param {String} nameEn 英文名称颜色库名称
     * @param {String} color1  颜色一
     * @param {String} color2  颜色二
     * @param {String} color3  颜色三
     * @param {String} color4  颜色四
     * @param {String} color5  颜色五
     * @param {String} color6  颜色六
     */
    colourManageSave(ctx, { ...data } = {}) {
      return api.post('/api/admin/instance/ganColorSheet/save', {
        data,
        toastError: false// 把默认的后端返回默认报错提示去掉，前端自己设置报错提示信息
      })
    },
  }
}
