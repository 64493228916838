// @file 案例管理
import api from '@/utils/ajax'

export default {
  namespaced: true,

  state: {},

  getters: {},

  actions: {
    /**
     * 案例管理列表
     * @param {*} ctx
     * @param {Number} themeId id
     */
    caseManageList(ctx, { ...params } = {}) {
      return api.get('/api/admin/instance/gancase/list', {
        params
      })
    },
    /** 案例排序
     * @param {*} ctx
     * @param {Number} id id
     * @param {Number} type 类型：1为上移 2为下移
     */
    gancaseSort(ctx, { id, type } = {}) {
      return api.post('/api/admin/instance/gancase/sort', {
        data: {
          id,
          type
        }
      })
    },
    /**
     * 删除案例
     * @param {*} ctx
     * @param {Number} id 序列
     */
    caseManageRemove(ctx, { id } = {}) {
      return api.post('/api/admin/instance/gancase/delete', {
        data: {
          id,
        }
      })
    },
    /**
     * （没传入id）增加或（传入id）修改主题
     * @param {*} ctx
     * @param {Number} id 序列
     * @param {String} nameZhcn 案例名字
     * @param {String} parsingKey  图片唯一索引值
     * @param {String} sno  案例编号
     * @param {String} author  作者
     * @param {Number} themeId 主题标识
     * @param {String} nameZhtw  繁体名称
     * @param {String} nameEn  英文名称
     * @param {String} sourceCoverName  案例封面文件名
     * @param {String} sourceCoverUrl  案例封面链接
     */
    caseManageSave(ctx, { ...data } = {}) {
      return api.post('/api/admin/instance/gancase/save', {
        data
        // toastError: false// 把默认的后端返回默认报错提示去掉，前端自己设置报错提示信息
      })
    },
  }
}
