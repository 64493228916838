// @file 用户管理
import api from '@/utils/ajax'

export default {
  namespaced: true,

  state: {},

  getters: {},

  actions: {
    /**
     * 用户列表
     * @param {*} ctx
     * @param {Number} city 城市
     * @param {Number} country 国家
     * @param {Number} email 邮件
     * @param {Number} gender 性别
     * @param {Number} phone 电话
     * @param {Number} type 类型
     */
    userManageList(ctx, { city, country, email, gender, phone, type, page, size } = {}) {
      return api.post('/api/admin/instance/ganUser/list', {
        data: {
          city,
          country,
          email,
          gender,
          phone,
          type,
          page,
          size
        }
      })
    },

    /**
     * 用户表格数据的导出
     * @param {*} ctx
     * @param {String} url 地址
     * @param {Number} city 城市
     * @param {Number} country 国家
     * @param {Number} email 邮件
     * @param {Number} gender 性别
     * @param {Number} phone 电话
     * @param {Number} type 类型
     */
    userManagedownloadtable(ctx, { url, city, country, email, gender, phone, type } = {}) {
      return api.export(url, {
        city,
        country,
        email,
        gender,
        phone,
        type,
      })
    },
  }
}
