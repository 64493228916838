import api from '@/utils/ajax'

export default {
  namespaced: true,

  state: {
    menuList: [],
    authCache: [], // 权限列表
  },

  getters: {},

  mutations: {
    setMenuList(state, menuList) {
      state.menuList = menuList
    },
    setAuthCache(state, authCache) {
      state.authCache = authCache
    }
  },

  actions: {
    /**
     * 登录
     * @param {*} ctx
     * @param {String} account 账号
     * @param {String} passwd 密码
     */
    async authAppList(ctx, { account, passwd } = {}) {
      const data = await api.post('/admin/auth/common/authUser/login.do', {
        data: {
          account,
          passwd
        }
      })
      sessionStorage.setItem('userInfo', JSON.stringify(data))
      sessionStorage.setItem('sessionKey', data.sessionKey)
      return data
    },
    /**
     * 获取菜单
     * @param {*} ctx
     */
    async getMenu(ctx) {
      const data = await api.post('/admin/auth/instance/authMenu/listUserMenu.do')
      ctx.commit('setMenuList', data.menus)
      ctx.commit('setAuthCache', data.auth)
      return data
    }
  }
}
