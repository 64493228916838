import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/utils/ajax'

import auth from './module/auth'
import login from './module/login'
import themes from './module/themes'
import caseManage from './module/caseManage'
import colourManage from './module/colourManage'
import viewpagerManage from './module/viewpagerManage'
import userManage from './module/userManage'
import statistics from './module/statistics'
import aiPuzzleManage from './module/aiPuzzleManage'
import playerCaseManage from './module/playerCaseManage'

Vue.use(Vuex)

export const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  modules: {
    auth,
    login,
    themes,
    caseManage,
    colourManage,
    viewpagerManage,
    userManage,
    statistics,
    aiPuzzleManage,
    playerCaseManage,
  },

  state: {
  },
  mutations: {
  },
  actions: {
    /**
     * 下载/导出
     * @param {*} ctx
     * @param {String} url 地址
     */
    download(ctx, { url } = {}) {
      return api.export(url)
    },
  }
})

export default store
