// @file 首页轮播管理
import api from '@/utils/ajax'

export default {
  namespaced: true,

  state: {},

  getters: {},

  actions: {
    /**
     * 首页轮播图列表
     * @param {*} ctx
     * @param {Number} id 序列id
     */
    viewpagerManageList(ctx, { id, page, size } = {}) {
      return api.get('/api/admin/instance/ganStand/list', {
        params: {
          id,
          page,
          size
        }
      })
    },
    /** 首页轮播图排序
     * @param {*} ctx
     * @param {Number} id id
     * @param {Number} type 类型：1为上移 2为下移
     */
    viewpagerManageSort(ctx, { id, type } = {}) {
      return api.post('/api/admin/instance/ganStand/sort', {
        data: {
          id,
          type
        }
      })
    },
    /**
     * （没传入id）增加或（传入id）修改主题
     * @param {*} ctx
     * @param {Number} id id
     * @param {String} name 名称
     * @param {String} resourceNameHorEn 横屏英文资源名称
     * @param {String} resourceNameHorZhcn 横屏简体中文资源名称
     * @param {String} resourceNameHorZhtw 横屏繁体中文资源名称
     * @param {String} resourceNameVerEn  英文资源名称
     * @param {String} resourceNameVerZhcn  简体中文资源名称
     * @param {String} resourceNameVerZhtw  繁体中文资源名称
     * @param {String} resourceUrlHorEn  横屏英文资源url
     * @param {String} resourceUrlHorZhcn  横屏简体中文资源url
     * @param {String} resourceUrlHorZhtw  横屏繁体中文资源url
     * @param {String} resourceUrlVerEn  英文资源url
     * @param {String} resourceUrlVerZhcn  简体中文资源url
     * @param {String} resourceUrlVerZhtw  繁体中文资源url
     */
    viewpagerManageSave(ctx, {
      id,
      name,
      resourceNameHorEn,
      resourceNameHorZhcn,
      resourceNameHorZhtw,
      resourceNameVerEn,
      resourceNameVerZhcn,
      resourceNameVerZhtw,
      resourceUrlHorEn,
      resourceUrlHorZhcn,
      resourceUrlHorZhtw,
      resourceUrlVerEn,
      resourceUrlVerZhcn,
      resourceUrlVerZhtw,
      type
    } = {}) {
      return api.post('/api/admin/instance/ganStand/save', {
        data: {
          id,
          name,
          resourceNameHorEn,
          resourceNameHorZhcn,
          resourceNameHorZhtw,
          resourceNameVerEn,
          resourceNameVerZhcn,
          resourceNameVerZhtw,
          resourceUrlHorEn,
          resourceUrlHorZhcn,
          resourceUrlHorZhtw,
          resourceUrlVerEn,
          resourceUrlVerZhcn,
          resourceUrlVerZhtw,
          type
        },
        // toastError: false// 把默认的后端返回默认报错提示去掉，前端自己设置报错提示信息
      })
    },
    /**
     * 删除指定列数据
     * @param {*} ctx
     * @param {Number} id 标识id
     */
    viewpagerManageRemove(ctx, { id } = {}) {
      return api.post('/api/admin/instance/ganStand/delete', {
        data: {
          id,
        }
      })
    },
    /**
     * 更新轮播图时间间隔
     * @param {*} ctx
     * @param {String} standTime 时间间隔
     */
    viewpagerTime(ctx, { standTime } = {}) {
      return api.post('/api/admin/instance/ganStand/updateSetting', {
        data: {
          standTime,
        }
      })
    },
    /**
     * 获取轮播图时间间隔
     * @param {*} ctx
     * @param {String} standTime 时间间隔
     */
    viewpagerGetTime(ctx, { standTime } = {}) {
      return api.get('/api/admin/instance/ganStand/getSetting', {
        data: {
          standTime,
        }
      })
    },
  }
}
