import api from '@/utils/ajax'

export default {
  namespaced: true,

  state: {},

  getters: {},

  actions: {
    /**
     * 获取主题列表
     * @param {*} ctx
     * @param {Number} page 页码
     * @param {Number} size 每页条数
     */
    async themeList(ctx, { page, size, puzzleType } = {}) {
      return await api.get('/api/admin/instance/ganTheme/list', {
        params: {
          page,
          size,
          puzzleType,
        }
      })
    },
    /**
     * 获取主题详情
     * @param {*} ctx
     * @param {Number} id 序列
     */
    themeDatail(ctx, { id } = {}) {
      return api.get('/api/admin/instance/ganTheme/get', {
        params: {
          id
        }
      })
    },
    /**
     * （没传入id）增加或（传入id）修改主题
     * @param {*} ctx
     * @param {Number} id id
     * @param {Number} isVisible 受否显示
     * @param {String} nameZhcn 主题名字
     * @param {String} nameZhtw 繁体名字
     * @param {String} nameEn 英文名字
     */
    themeSave(ctx, { ...data } = {}) {
      return api.post('/api/admin/instance/ganTheme/save', {
        data
      })
    },
    /**
     * 删除主题
     * @param {*} ctx
     * @param {Number} id 序列
     */
    themeRemove(ctx, { id } = {}) {
      return api.post('/api/admin/instance/ganTheme/delete', {
        data: {
          id,
        }
      })
    },
    /**
     * 主题排序
     * @param {*} ctx
     * @param {Number} id id
     * @param {Number} type 类型：1为上移 2为下移
     */
    ganThemeSort(ctx, { id, type } = {}) {
      return api.post('/api/admin/instance/ganTheme/sort', {
        data: {
          id,
          type
        }
      })
    },
  },

  mutations: {}
}
