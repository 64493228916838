import api from '@/utils/ajax'

export default {
  namespaced: true,

  state: {
    authAppList: [], // 系统列表
    authAppInstanceList: [], // 系统实例列表
    companyList: [], // 集团列表
  },

  getters: {},

  mutations: {
    setAuthAppList(state, authAppList) {
      state.authAppList = authAppList
    },
    setAuthAppInstanceList(state, authAppInstanceList) {
      state.authAppInstanceList = authAppInstanceList
    },
    setCompanyList(state, companyList) {
      state.companyList = companyList
    }
  },

  actions: {
    /**
     * 获取系统列表
     * @param {*} ctx
     * @param {Number} page 页码
     * @param {Number} size 每页条数
     * @param {String} quickSearch 关键字
     * @param {String} type 系统类型 0内部系统 1外部系统
     */
    async authAppList(ctx, { page = 1, size = 10, quickSearch, type } = {}) {
      const data = await api.post('/admin/auth/instance/authApp/list.do', {
        data: {
          quickSearch,
          type,
          page,
          size
        }
      })
      ctx.commit('setAuthAppList', data.list)
      return data
    },

    /**
     * 添加系统 / 修改系统
     * @param {*} ctx
     * @param {Number} id 系统ID,修改系统时传入
     * @param {String} code 系统英文编码
     * @param {String} cnName 系统中文名称
     * @param {String} descript 系统描述
     */
    authAppSave(ctx, { id, code, cnName, descript } = {}) {
      return api.post('/admin/auth/instance/authApp/save.do', {
        data: {
          id,
          code,
          cnName,
          descript
        }
      })
    },

    /**
     * 获取系统实例列表
     * @param {*} ctx
     * @param {Number} page 页码
     * @param {Number} size 每页条数
     * @param {String} quickSearch 查询字段
     * @param {String} type 类型
     */
    async authAppInstanceList(ctx, { page = 1, size = 10, quickSearch, type } = {}) {
      const data = await api.post('/admin/auth/instance/authAppInstance/list.do', {
        data: {
          page,
          size,
          quickSearch,
          type
        }
      })
      ctx.commit('setAuthAppInstanceList', data.list)
      return data
    },

    /**
     * 添加系统实例/ 修改系统实例
     * @param {*} ctx
     * @param {Number} id 实例ID,修改系统时传入
     * @param {String} appId 关联的系统id
     * @param {String} name 实例名称
     */
    async authAppInstanceSave(ctx, { id, appId, name } = {}) {
      return await api.post('/admin/auth/instance/authAppInstance/save.do', {
        data: {
          id,
          appId,
          name
        }
      })
    },

    /**
     * 保存用户实例(关联角色)
     * @param {Number} id 实例ID,修改系统时传入
     * @param {String} appInstanceId 系统实例
     * @param {String} roleId 角色id
     * @param {String} userId 用户id
     */
    async instanceSaveByRole(ctx, { id, appInstanceId, roleId, userId } = {}) {
      return await api.post('/admin/auth/instance/authAppUserInstance/saveByRole.do', {
        data: {
          id,
          appInstanceId,
          roleId,
          userId
        }
      })
    },

    /**
     * 获取菜单列表
     * @param {*} ctx
     * @param {String} appId 系统ID
     * @param {String} appInstanceId 实例ID
     */
    async authMenuList(ctx, { appId, appInstanceId } = {}) {
      return await api.get('/admin/auth/instance/authMenu/list.do', {
        params: {
          appId,
          appInstanceId
        }
      })
    },
    /**
     * 保存菜单
     * @param {*} ctx
     * @param {String} appId 系统Id
     * @param {String} id 菜单Id
     * @param {String} sort 排序
     * @param {Object} children 子菜单
     * @param {String} level 层级
     * @param {String} name 菜单名称
     * @param {Object} resources 资源
     */
    async authMenuSave(ctx, { appId, id, sort, children, level, name, resources } = {}) {
      return await api.post('/admin/auth/instance/authMenu/save.do', {
        data: {
          appId,
          id,
          sort,
          children,
          level,
          name,
          resources
        }
      })
    },

    /**
     * 获取应用资源表
     * @param {*} ctx
     * @param {String} appId 系统ID
     * @param {String} appInstanceId 实例Id
     */
    async authResourcesListForApp(ctx, { appId, appInstanceId } = {}) {
      return await api.post('/admin/auth/instance/authResources/listForApp.do', {
        data: {
          appId,
          appInstanceId
        }
      })
    },

    /**
     * 获取资源列表
     * @param {*} ctx
     * @param {Number} appId 系统ID
     */
    async authResourcesList(ctx, { appId } = {}) {
      return await api.post('/admin/auth/instance/authResources/list.do', {
        data: {
          appId
        }
      })
    },

    /**
     * 资源列表保存
     * @param {*} ctx
     * @param {String} appId 系统Id
     * @param {String} id 菜单Id
     * @param {String} sort 排序
     * @param {Object} children 子菜单
     * @param {String} level 层级
     * @param {String} name 菜单名称
     * @param {String} type 类型
     */
    async authResourcesSave(ctx, { appId, id, sort, children, level, name, type } = {}) {
      return await api.post('/admin/auth/instance/authResources/save.do', {
        data: {
          appId,
          id,
          sort,
          children,
          level,
          name,
          type
        }
      })
    },

    /**
     * 获取集团列表
     * @param {*} ctx
     */
    async authCompany(ctx) {
      const data = await api.post('/admin/auth/instance/authCompany/listAll.do', {
        data: {}
      })
      ctx.commit('setCompanyList', data)
      return data
    },

    /**
     * 用户列表
     * @param {*} ctx
     * @param {Number} page 页码
     * @param {Number} size 每页条数
     * @param {Number} companyId 集团Id
     */
    async authUser(ctx, { page = 1, size = 10, companyId } = {}) {
      return await api.post('/admin/auth/instance/authUser/list.do', {
        data: {
          page,
          size,
          companyId
        }
      })
    },

    /**
     * 编辑用户
     * @param {Number} id id
     * @param {String} orgAccount 账号
     * @param {String} name 用户姓名
     * @param {String} email 用户邮箱
     * @param {Number} phone 用户手机
     * @param {String} passwd 用户密码
     * @param {String} repasswd 确认密码
     */
    async authUserSave(ctx, { orgAccount, name, email, phone, passwd, repasswd } = {}) {
      return await api.post('/admin/auth/instance/authUser/save.do', {
        data: {
          orgAccount,
          name,
          email,
          phone,
          passwd,
          repasswd
        }
      })
    },
    /**
     * 冻结/解冻用户
     * @param {*} ctx
     * @param {Object} data 用户信息
     */
    async authUserActive(ctx, data) {
      return await api.post('/admin/auth/instance/authUser/active.do', {
        data: data
      })
    },

    /**
     * 用户实例列表
     * @param {*} ctx
     * @param {Number} page 页码
     * @param {Number} size 每页条数
     * @param {Number} userId 用户Id
     */
    async authUserInstance(ctx, { page = 1, size = 10, userId } = {}) {
      return await api.post('/admin/auth/user/authAppUserInstance/listForPage.do', {
        data: {
          page,
          size,
          userId
        }
      })
    },

    /**
     * 根据系统实例获取角色列表
     * @param {*} ctx
     * @param {*} appInstanceId 实例Id
     */
    async authRoleList(ctx, { appInstanceId } = {}) {
      return await api.post('/admin/auth/user/authRole/list.do', {
        data: {
          appInstanceId
        }
      })
    },

    /**
     * 获取用户实例的菜单
     * @param {*} ctx
     * @param {String} id 实例Id
     */
    async authInstanceMenu(ctx, { id } = {}) {
      return await api.get('/admin/auth/instance/authAppUserInstance/get.do', {
        params: {
          id
        }
      })
    },
    /**
     * 保存用户实例
     * @param {*} ctx
     * @param {*} data 用户实例数据
     */
    async instanceSave(ctx, data) {
      return await api.post('/admin/auth/instance/authAppUserInstance/save.do', {
        data: data
      })
    },

    /**
     * 删除用户实例
     * @param {*} ctx
     * @param {*} ids 实例id
     */
    async userInstanceDelete(ctx, { ids } = {}) {
      return await api.get('/admin/auth/instance/authAppUserInstance/delete.do', {
        params: {
          ids: ids
        }
      })
    },

    /**
     * 分页获取角色列表
     * @param {*} ctx
     * @param {Number} page 页码
     * @param {Number} size 每页条数
     * @param {Number} companyId 集团ID
     */
    async authRole(ctx, { page = 1, size = 10, companyId } = {}) {
      return await api.post('/admin/auth/user/authRole/listForPage.do', {
        data: {
          page,
          size,
          companyId
        }
      })
    },

    /**
     * 保存角色
     * @param {*} ctx
     * @param {String} id id
     * @param {String} appId 系统Id
     * @param {String} code 角色code
     * @param {String} name 角色名称
     * @param {String} companyId 集团ID
     */
    async authRoleSingleSave(ctx, { id, appId, code, name, companyId } = {}) {
      return await api.post('/admin/auth/instance/authRole/singleSave.do', {
        data: {
          id,
          appId,
          code,
          name,
          companyId
        }
      })
    },

    /**
     * 保存角色
     * @param {*} ctx
     * @param {Object} data 角色数据
     */
    async authRoleSave(ctx, data) {
      return await api.post('/admin/auth/instance/authRole/save.do', {
        data: data
      })
    },

    /**
     * 角色实例数据获取
     * @param {*} ctx
     * @param {*} id 角色id
     */
    async getAuthRole(ctx, { id } = {}) {
      return await api.post('/admin/auth/instance/authRole/get.do', {
        data: {
          id
        }
      })
    },

    /**
     * 分页获取集团列表
     * @param {*} ctx
     * @param {Number} page 页码
     * @param {Number} size 每页条数
     */
    async authCompanyPage(ctx, { page = 1, size = 10 } = {}) {
      return await api.post('/admin/auth/instance/authCompany/list.do', {
        data: {
          page,
          size
        }
      })
    },

    /**
     * 编辑集团信息
     * @param {*} ctx
     * @param {String} name 集团名称
     * @param {String} shortName 集团简称
     * @param {String} account 集团账号
     * @param {String} appId 关联系统
     * @param {String} ext 扩展字段 { appId: '', appSecret: ''}
     */
    async authCompanySave(ctx, { name, shortName, account, appId, ext } = {}) {
      return await api.post('/admin/auth/instance/authCompany/save.do', {
        data: {
          name,
          shortName,
          account,
          appId,
          ext
        }
      })
    },

    /**
     * 分页获取登录日志
     * @param {*} ctx
     * @param {Number} page 页码
     * @param {Number} size 每页条数
     */
    async authLoginLog(ctx, { page = 1, size = 10 } = {}) {
      return await api.post('/admin/auth/instance/authLoginLog/list.do', {
        data: {
          page,
          size
        }
      })
    },

    /**
     * 分页获取参数设置列表
     * @param {*} ctx
     * @param {Number} page 页码
     * @param {Number} size 每页条数
     */
    async authConfig(ctx, { page = 1, size = 10 } = {}) {
      return await api.post('/admin/auth/instance/authConfig/list.do', {
        data: {
          page,
          size
        }
      })
    },

    /**
     * 参数设置
     * @param {*} ctx
     * @param {String} id id
     * @param {String} key 键
     * @param {String} value 值
     */
    async authConfigSave(ctx, { id, key, value } = {}) {
      return await api.post('/admin/auth/instance/authConfig/save.do', {
        data: {
          id,
          key,
          value
        }
      })
    },
  }
}
